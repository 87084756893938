import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { AppLogger } from '@just-ai/logger';
import { Button } from '@just-ai/just-ui';

import { t } from 'localization';
import { isEuroInstance } from 'isAccessFunction';

import styles from './styles.module.scss';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};
const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  return (
    <div className={styles.AppErrorBoundary} role='alert'>
      <div className={styles.AppErrorBoundary__title}>{t('GlobalErrorBoundary:Text')}</div>
      {!isEuroInstance() && (
        <div
          className={styles.AppErrorBoundary__description}
          dangerouslySetInnerHTML={{
            __html: t('GlobalErrorBoundary:Description', {
              support: `<a href="${t('support requests link')}" target="_blank" rel="noopener noreferrer">${t('GlobalErrorBoundary:Support')}</a>`,
            }),
          }}
        ></div>
      )}

      {error.cause && <div className={styles.AppErrorBoundary__description}>{error.cause}</div>}
      <Button color='primary' onClick={resetErrorBoundary} className='margin-top-2x'>
        {t('GlobalErrorBoundary:TryAgain')}
      </Button>
    </div>
  );
};

interface AppErrorBoundaryProps {
  prefix: string;
  children: React.ReactNode;
}
const AppErrorBoundary = ({ prefix, children }: AppErrorBoundaryProps) => {
  const onError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      const message = `${prefix} => ${error.message}`;
      AppLogger.fatal(
        {
          message: message,
          exception: error,
        },
        info
      );
    },
    [prefix]
  );

  if (children === undefined) return null;
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

export function addAppErrorBoundaryHOC<PROPS extends {}>(prefix: string, Cmp: React.ComponentType<PROPS>) {
  return (props: PROPS) => {
    return (
      <AppErrorBoundary prefix={prefix}>
        <Cmp {...(props as PROPS)} />
      </AppErrorBoundary>
    );
  };
}

export default AppErrorBoundary;
