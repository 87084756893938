import { Ace } from 'ace-builds';
import { State } from 'modules/Editor/api/client';
import { Intents } from './Intents';
import { States } from './States';
import { countIndentLevel, getCurrentTabIndent, getCustomTagParametersTokens } from './utils';
import { BaseCompleter, CompletionType } from './BaseCompleter';
import {
  JS_COMPLETIONS,
  ES6_COMPLETIONS,
  RANDOM_COMPLETIONS,
  STATE_COMPLETIONS,
  THEME_COMPLETIONS,
} from './completionConstants';

import { ButtonsCompleter } from './ButtonsCompleter';
import { ExtendedJsCompleter } from './ExtendedJsCompeter';
import { TagParamsCompleter } from './TagParamsCompleter';
import { CustomTagsStore$ } from '../../../../../reducers/JGraph.reducer/customTags.store';
import { CustomTagParamsCompleter } from './CustomTagsParamsCompleter';
import { LexerContext } from './types';
import { InlineButtonsCompleter } from './InlineButtonsCompleter';
import { PATH_CONTEXTS } from './consts';

export class ScCompleter implements Ace.Completer {
  intents: Intents;
  intentGroups: Intents;
  themeCompleter: BaseCompleter;
  baseStateCompleter: BaseCompleter;
  randomCompleter: BaseCompleter;
  baseJsCompleter: BaseCompleter;
  baseEs6Completer: BaseCompleter;
  extendedJsCompleter: ExtendedJsCompleter;
  extendedEs6Completer: ExtendedJsCompleter;
  buttonsCompleter: ButtonsCompleter;
  inlineButtonsCompleter: InlineButtonsCompleter;
  tagParamsCompleter: TagParamsCompleter;
  customTagsParamsCompleter: CustomTagParamsCompleter;
  states: States;

  constructor(intents: string[], intentGroups: string[], states: State[], fileName: string) {
    this.intents = new Intents(intents, ['intent:', 'intent!:'], 'intent');
    this.intentGroups = new Intents(intentGroups, ['intentGroup:', 'intentGroup!:'], 'intentGroup');
    this.themeCompleter = new BaseCompleter(
      THEME_COMPLETIONS,
      THEME_COMPLETIONS.map(words => words[0]),
      'theme'
    );
    const customTags = CustomTagsStore$.getValue().map(customTag => customTag.tagName! + ':');
    const STATE_COMPLETIONS_MERGE = [...STATE_COMPLETIONS, ...customTags];
    this.baseStateCompleter = new BaseCompleter(
      STATE_COMPLETIONS_MERGE,
      STATE_COMPLETIONS_MERGE.map(words => words[0]),
      'state'
    );
    this.randomCompleter = new BaseCompleter(
      RANDOM_COMPLETIONS,
      RANDOM_COMPLETIONS.map(words => words[0]),
      'random'
    );
    this.buttonsCompleter = new ButtonsCompleter([], [], 'buttons');
    this.inlineButtonsCompleter = new InlineButtonsCompleter([], [], 'inlineButtons');

    const jsCompletionStrings = ['$', 'cur', 'has', 'l', 'toP'];
    this.baseJsCompleter = new BaseCompleter(JS_COMPLETIONS, jsCompletionStrings, 'js', 20_000);
    this.baseEs6Completer = new BaseCompleter(ES6_COMPLETIONS, jsCompletionStrings, 'es6', 20_000);
    this.tagParamsCompleter = new TagParamsCompleter('tagParams');

    this.extendedJsCompleter = new ExtendedJsCompleter([], [], 'js');
    this.extendedEs6Completer = new ExtendedJsCompleter([], [], 'es6');

    this.states = new States(states, fileName);
    this.customTagsParamsCompleter = new CustomTagParamsCompleter();
  }

  setIntents(intents: string[]) {
    this.intents = new Intents(intents, ['intent:', 'intent!:'], 'intent');
  }

  setIntentGroups(intentGroups: string[]) {
    this.intentGroups = new Intents(intentGroups, ['intentGroup:', 'intentGroup!:'], 'intentGroup');
  }

  setStates(states: State[], fileName: string) {
    this.states = new States(states, fileName);
  }

  getBlockContext(
    session: Ace.EditSession,
    position: Ace.Point,
    currentTabIndent: number
  ): { currentContext: string; contextPosition: Ace.Point } {
    const line = session.getLine(position.row);
    let tokens = line.slice(0, line.length).split(session.getTabString());

    const currentRowTabIndent = getCurrentTabIndent(tokens);
    tokens = tokens.filter(token => token);
    const tokensSplittedBySpace = tokens.map(token => token.split(' ')).flat();
    const highLightTokens = session.getTokens(position.row).map(token => token.type);

    if (
      position.row > 0 &&
      (!(
        highLightTokens.includes('variable.parameter.zb') ||
        highLightTokens.includes('keyword.zb') ||
        highLightTokens.includes('storage.type.zb')
      ) ||
        currentRowTabIndent >= currentTabIndent)
    ) {
      --position.row;
      return this.getBlockContext(session, position, currentTabIndent);
    }

    return {
      currentContext: tokensSplittedBySpace[0] || 'empty',
      contextPosition: { ...position },
    };
  }

  _getContext(
    tokens: Ace.Token[],
    session: Ace.EditSession,
    position: Ace.Point,
    currentTabIndent: number,
    lexerContext: LexerContext
  ) {
    const firstTokenAsKeyword = tokens.find(token =>
      ['variable.parameter.zb', 'keyword.zb', 'storage.type.zb'].includes(token.type)
    );

    if (!firstTokenAsKeyword || (!this.tagParamsCompleter.isApplicable(tokens) && lexerContext.state !== 'tagParams')) {
      return this.getBlockContext(session, { ...position }, currentTabIndent);
    }

    return {
      currentContext: `inline:${firstTokenAsKeyword.value}`,
      contextPosition: position,
    };
  }

  getCompletions(
    _editor: Ace.Editor,
    session: Ace.EditSession,
    position: Ace.Point,
    _prefix: string,
    callback: Ace.CompleterCallback
  ) {
    const line = session.getLine(position.row);
    const tokens = this.tokenizeLine(line.slice(0, position.column));
    const tabTokens = line.slice(0, position.column).split(session.getTabString());
    const originalPosition = { ...position };
    let currentTabIndent = getCurrentTabIndent(tabTokens);
    if (!session) return;
    const tokensInRow = session.getTokens(position.row);
    const lexerContext = session
      .getMode()
      .getTokenizer()
      .getLineTokens(tokens.join(' '), session.getState(position.row)) as unknown as LexerContext;

    const { currentContext, contextPosition } = this._getContext(
      tokensInRow,
      session,
      position,
      currentTabIndent,
      lexerContext
    );

    const lastToken = tokens[tokens.length - 1];
    const lastTabToken = tabTokens[tabTokens.length - 1];

    switch (currentContext) {
      case 'theme:': {
        if (this.themeCompleter.isApplicable(tokens)) {
          callback(null, this.themeCompleter.getCompletions(lastToken));
        }
        return;
      }
      case 'state:': {
        const indentLevel = countIndentLevel(line) - 1; // -1 to adjust for go:/go!: indent

        if (this.states.isApplicable(tokens, position.row, indentLevel)) {
          callback(null, this.states.getCompletions(lastToken, position.row, indentLevel));
        } else if (this.baseStateCompleter.isApplicable(tokens)) {
          callback(null, this.baseStateCompleter.getCompletions(lastToken));
        }

        return;
      }
      case 'random:': {
        if (this.baseStateCompleter.isApplicable(tokens)) {
          callback(null, this.randomCompleter.getCompletions(lastToken));
        }
        return;
      }

      case 'script:': {
        const completions: CompletionType = [];
        if (this.baseJsCompleter.isApplicable(tabTokens)) {
          completions.push(...this.baseJsCompleter.getCompletions(lastTabToken.replace('script:', '').trim()));
        }
        if (this.extendedJsCompleter.isApplicable(tabTokens)) {
          completions.push(...this.extendedJsCompleter.getCompletions(lastTabToken.trim()));
        }
        callback(null, completions);
        return;
      }
      case 'scriptEs6:': {
        const completions: CompletionType = [];
        if (this.baseEs6Completer.isApplicable(tabTokens)) {
          completions.push(...this.baseEs6Completer.getCompletions(lastTabToken.replace('scriptEs6:', '').trim()));
        }
        if (this.extendedEs6Completer.isApplicable(tabTokens)) {
          completions.push(...this.extendedEs6Completer.getCompletions(lastTabToken.trim()));
        }
        callback(null, completions);
        return;
      }

      case 'buttons:': {
        if (
          tabTokens.filter(token => token).length <= 1 &&
          tabTokens.every(token => !token.includes('->') && !token.includes('{ text:'))
        ) {
          callback(null, this.buttonsCompleter.getCompletions(tabTokens[tabTokens.length - 1]));
        }
        return;
      }
      case 'inlineButtons:': {
        if (tabTokens.filter(token => token).length <= 1 && tabTokens.every(token => !token.includes('{'))) {
          callback(null, this.inlineButtonsCompleter.getCompletions(tabTokens[tabTokens.length - 1]));
        }
        return;
      }
      default: {
        const contextCustomTag = CustomTagsStore$.getValue().find(
          customTagDescriptor => customTagDescriptor.tagName + ':' === currentContext
        );
        if (contextCustomTag && currentContext && this.customTagsParamsCompleter.isApplicable(session, position)) {
          let contextStartRow = contextPosition.row + 1;
          const customTagParametersTokens = getCustomTagParametersTokens(contextStartRow, currentTabIndent, session);
          this.customTagsParamsCompleter.setCustomTagParameters(
            customTagParametersTokens,
            contextCustomTag.parameters,
            this.states
          );

          callback(null, this.customTagsParamsCompleter.getCompletions());
        }
        break;
      }
    }

    if (currentContext?.startsWith('inline:')) {
      const tagName = currentContext?.replace(/inline:/, '');
      if (this.tagParamsCompleter.isApplicable(lexerContext.tokens)) {
        callback(
          null,
          this.tagParamsCompleter.getCompletions(tagName, originalPosition, session, lexerContext.tokens, tokensInRow)
        );
      }
    }

    if (this.intentGroups.isApplicable(tokens)) {
      callback(null, this.intentGroups.getCompletions(lastToken));
    }

    if (this.intents.isApplicable(tokens)) {
      callback(null, this.intents.getCompletions(lastToken));
    }
  }

  getDocTooltip = (item: { docHTML: string; type: string } & Ace.Completion) => {
    if (item.meta === '$reactions' && !item.docHTML) {
      if (item.value === '$reactions.ttsWithVariables()') {
        item.docHTML = '';
      }
    }
  };

  /**
   * A line tokenizer that splits lines by spaces, but keeps path context values as a single token.
   *
   * @param line - line to tokenize
   * @returns array of tokens
   */
  tokenizeLine = (line: string): string[] => {
    const tokens = line.split(' ');
    const pathContextTagPosition = tokens.findIndex(token => PATH_CONTEXTS.includes(token));

    if (pathContextTagPosition === -1) return tokens;

    const pathPosition = tokens.findIndex((token, index) => index > pathContextTagPosition && !!token);
    const paramSeparatorPosition = tokens.findIndex(token => token === '||');

    if (paramSeparatorPosition === -1) return [...tokens.slice(0, pathPosition), tokens.slice(pathPosition).join(' ')];

    return [
      ...tokens.slice(0, pathPosition),
      tokens.slice(pathPosition, paramSeparatorPosition).join(' '),
      ...tokens.slice(paramSeparatorPosition),
    ];
  };
}
