import React, { useMemo, useRef, useCallback } from 'react';

import { ComputePositionConfig, flip, offset, shift } from '@floating-ui/react-dom';

import { t } from 'localization';
import { useAppSelector, useAppDispatch } from 'storeHooks';

import { useCopyPasteState, useStateInClipboard } from 'utils/hooks/CopyToClipboardFeature';
import { isMac } from 'utils/platform';
import useFloaterPosition from 'utils/hooks/useFloaterPosition';
import { closeContextMenu, openScreenCreationMenu } from 'reducers/JGraph.reducer';
import { useStageObservableContext } from 'modules/JGraph/contexts/StageObservablesProvider';
import { Vector2D } from 'modules/JGraph/utils/2DVector';
import { stateWidth } from 'modules/JGraph/utils/stageUtils';

import { useStickerActions } from '../Sticker/hooks';
import { stickerSideSize } from '../Sticker/consts';
import styles from './styles.module.scss';

const floaterOptions: Partial<ComputePositionConfig> = {
  strategy: 'fixed',
  placement: 'bottom-start',
  middleware: [
    offset({
      mainAxis: -2,
      alignmentAxis: -2,
    }),
    flip(),
    shift(),
  ],
};

const ContextMenu = () => {
  const dispatch = useAppDispatch();
  const observableProps = useStageObservableContext();
  const { contextMenu, selectedTheme } = useAppSelector(state => ({
    contextMenu: state.JGraphReducer.contextMenu,
    selectedTheme: state.JGraphReducer.selectedTheme,
  }));
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const stickerActions = useStickerActions();

  const createSticker = useCallback(() => {
    dispatch(closeContextMenu());

    const position = contextMenu.statePosition
      ? Vector2D.fromObj(contextMenu.statePosition)
          .addX(stateWidth + stickerSideSize.width / 2 + 104)
          .addY(stickerSideSize.height / 2)
      : contextMenu.screenPosition;

    stickerActions.createSticker(position, contextMenu.statePath);
  }, [contextMenu, dispatch, stickerActions]);

  const addNewStep = useCallback(() => {
    dispatch(closeContextMenu());
    dispatch(
      openScreenCreationMenu({
        screenPosition: contextMenu.screenPosition,
        pointerPosition: contextMenu.pointerPosition,
        parentStatePath: observableProps.selectedGroupPath,
        parentThemeValue: selectedTheme?.value,
      })
    );
  }, [
    contextMenu.pointerPosition,
    contextMenu.screenPosition,
    dispatch,
    observableProps.selectedGroupPath,
    selectedTheme,
  ]);

  useFloaterPosition({
    enable: contextMenu.open,
    floaterElement: wrapperRef,
    target: useMemo(
      () => ({
        width: 0,
        height: 0,
        x: contextMenu.pointerPosition.x,
        y: contextMenu.pointerPosition.y,
      }),
      [contextMenu.pointerPosition]
    ),
    options: floaterOptions,
  });

  const { pasteState } = useCopyPasteState();
  const isStateInClipboardExist = useStateInClipboard();
  const pasteStateInner = useCallback(() => {
    if (!isStateInClipboardExist) return;
    dispatch(closeContextMenu());
    pasteState();
  }, [dispatch, isStateInClipboardExist, pasteState]);

  if (!contextMenu.open) return null;

  return (
    <div className={styles.ContextMenu} ref={wrapperRef}>
      {!contextMenu.statePath && (
        <div className={styles.ContextMenu__item} onClick={addNewStep}>
          {t('JGraph:ContextMenu:AddState')}
        </div>
      )}
      <div className={styles.ContextMenu__item} onClick={createSticker}>
        {t('JGraph:ContextMenu:AddSticker')}
      </div>
      <div className={styles.ContextMenu__item} data-disable={!isStateInClipboardExist} onClick={pasteStateInner}>
        {t('JGraph:ContextMenu:PasteState', { cmd: isMac() ? '⌘-V' : 'Ctrl-V' })}
      </div>
    </div>
  );
};

export default React.memo(ContextMenu);
