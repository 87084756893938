import { createAsyncThunk } from '@reduxjs/toolkit';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { ComplexRenameStateData } from 'modules/Editor/api/client';

import { getGraph } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { findScreenByPath } from 'reducers/JGraph.reducer/Graph';

import { renameNameInPath } from '../utils';
import complexRenameState from './complexRenameState';

const complexRenameStateInReduxAndApi = createAsyncThunk(
  'JGraph/complexRenameStateInReduxAndApi',
  async (complexRenameStateData: Omit<ComplexRenameStateData, 'files'>, thunkAPI) => {
    mainSave$.next({
      type: 'updateStateInReduxAndApi',
      path: complexRenameStateData.statePath,
      action: () => thunkAPI.dispatch(complexRenameState(complexRenameStateData)),
    });
    mainSave$.next({
      type: 'syncGraph',
      path: complexRenameStateData.statePath,
      action: () => thunkAPI.dispatch(getGraph({})),
    });
  }
);

export const revertUpdatingStateInReduxAndApi = createAsyncThunk(
  'JGraph/revertComplexRenameStateInReduxAndApi',
  async (revertEvent: RevertEvent<ComplexRenameStateData>, thunkAPI) => {
    const prevScreens = revertEvent.prevState.JGraphReducer.graph.blocks;
    const prevScreen = findScreenByPath(revertEvent.payload.statePath, prevScreens);
    if (!prevScreen) return;

    const currentPath = renameNameInPath(prevScreen.path, revertEvent.payload.newValue);
    const revertNames = {
      statePath: currentPath,
      oldValue: revertEvent.payload.newValue,
      newValue: revertEvent.payload.oldValue,
    };

    mainSave$.next({
      type: 'update',
      path: currentPath,
      action: () => thunkAPI.dispatch(complexRenameState(revertNames)),
    });

    mainSave$.next({
      type: 'syncGraph',
      path: revertNames.statePath,
      action: () => thunkAPI.dispatch(getGraph({})),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(
  revertUpdatingStateInReduxAndApi,
  complexRenameStateInReduxAndApi
);
