import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { RootState } from 'storeTypes';
import { isSystemAccess } from 'isAccessFunction';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { JGraphLicensed } from 'modules/JGraph/constants';
import { blockModalSubject$ } from 'modules/JGraph/view/BlockModal';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { getMinMaxScreensCoords } from 'modules/JGraph/utils/common';
import { joinPaths, scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';
import { findScreenByPath, getAllStates, getValidKonvaName } from 'reducers/JGraph.reducer/Graph';
import { restoreState, reverting, setEditMenuBlock, updateLastModification } from '../index';
import { getGraph, getJGraphVisuals } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { getLastPartOfPath, renameInlineStateIfNameIsBusy, stateChangePath } from 'modules/JGraph/utils/themesUtils';
import { getStickerAfterChangeStateLevel, insertStickersIdsToStates } from 'modules/JGraph/view/Sticker/utils';

import { GraphApi, getParentPaths } from '../utils';
import batchUpdateStickers from './batchUpdateStickers';

export const moveStateInGroup = createAsyncThunk(
  'JGraph/moveStateInGroup',
  async (moveStatePath: { what: string; where: string }, thunkAPI) => {
    let state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;
    if (!isSystemAccess([JGraphLicensed])) {
      blockModalSubject$.next(true);
      return;
    }

    let jStateToMove = cloneDeep(findScreenByPath(moveStatePath.what, state.JGraphReducer.graph.blocks));
    if (!jStateToMove) return;
    const jStateIntoMove = findScreenByPath(moveStatePath.where, state.JGraphReducer.graph.blocks);
    const jThemeIntoMove = state.JGraphReducer.graph.themes.find(theme => theme.value === moveStatePath.where);
    const targetEntity = jStateIntoMove
      ? {
          x: jStateIntoMove.x,
          y: jStateIntoMove.y,
          path: jStateIntoMove.path,
          fileName: jStateIntoMove.filename,
          isTheme: false,
          screens: jStateIntoMove.states || [],
        }
      : jThemeIntoMove
        ? {
            x: jThemeIntoMove.x,
            y: jThemeIntoMove.y,
            path: jThemeIntoMove.value,
            fileName: jThemeIntoMove.mainFileName,
            isTheme: true,
            screens: state.JGraphReducer.graph.blocks.filter(screen => screen.value === jThemeIntoMove.value),
          }
        : undefined;
    if (!targetEntity) return;

    const stateBeforeUpdate = cloneDeep(jStateToMove);

    const { data } = await GraphApi.deleteState(userAccountId || accountId, projectShortName, {
      targetStatePath: jStateToMove.path,
      file: {
        filename: jStateToMove.filename,
        lastModification: state.JGraphReducer.graph.files[jStateToMove.filename],
      },
      includeChildren: true,
    });
    thunkAPI.dispatch(
      updateLastModification({ lastModification: data.lastModification, filename: jStateToMove.filename })
    );
    state = thunkAPI.getState() as RootState;

    const busyPaths = getAllStates(state.JGraphReducer.graph.blocks).concat(
      state.JGraphReducer.graph.themes.map(el => el.value)
    );
    const newPath = joinPaths(getLastPartOfPath(moveStatePath.what), moveStatePath.where);
    jStateToMove = insertStickersIdsToStates(jStateToMove, state.JGraphReducer.stickers);
    jStateToMove = stateChangePath(jStateToMove, newPath);
    renameInlineStateIfNameIsBusy(jStateToMove, busyPaths);

    const { minX, minY } = getMinMaxScreensCoords(targetEntity.screens);
    jStateToMove.x = minX !== 0 ? minX - 450 : minX;
    jStateToMove.y = minY;

    await GraphApi.createState(userAccountId || accountId, projectShortName, {
      stateToCreate: jStateToMove,
      parentThemePath: jStateIntoMove?.theme || jThemeIntoMove?.value || '/',
      parentStatePath: jStateIntoMove?.path,
      file: {
        filename: targetEntity.fileName,
        lastModification: state.JGraphReducer.graph.files[targetEntity.fileName],
      },
    });

    const stickersWithUpdatedPositions = getStickerAfterChangeStateLevel(
      stateBeforeUpdate,
      jStateToMove,
      state.JGraphReducer.stickers
    );
    await thunkAPI.dispatch(batchUpdateStickers(stickersWithUpdatedPositions));
    await thunkAPI.dispatch(getGraph({}));

    thunkAPI.dispatch(setEditMenuBlock(undefined));

    mainSave$.next({
      type: 'delete',
      path: jStateToMove.path,
      action: () => Promise.resolve(),
    });

    scrollToTargetGlobal$.next({
      targetPathId: getValidKonvaName(jStateToMove.path),
    });

    return jStateToMove.path;
  }
);

export const revertMoveStateInGroup = createAsyncThunk(
  'JGraph/revertMoveStateInGroup',
  async (revertEvent: RevertEvent<{ what: string; where: string }, string>, thunkAPI) => {
    const revertScreen = findScreenByPath(revertEvent.payload.what, revertEvent.prevState.JGraphReducer.graph.blocks);
    if (!revertScreen) return;
    thunkAPI.dispatch(reverting(true));
    const { parentStatePath } = getParentPaths(revertEvent.payload.what, revertScreen.theme);
    thunkAPI.dispatch(
      restoreState({
        screen: revertScreen,
      })
    );

    let state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const stateToDelete = findScreenByPath(revertEvent.result, state.JGraphReducer.graph.blocks);

    if (!stateToDelete) {
      thunkAPI.dispatch(reverting(false));
      return;
    }

    const { data: deleteStateLastModification } = await GraphApi.deleteState(
      userAccountId || accountId,
      projectShortName,
      {
        targetStatePath: stateToDelete.path,
        file: {
          filename: stateToDelete.filename,
          lastModification: state.JGraphReducer.graph.files[stateToDelete.filename],
        },
        includeChildren: true,
      }
    );
    thunkAPI.dispatch(
      updateLastModification({
        lastModification: deleteStateLastModification.lastModification,
        filename: stateToDelete.filename,
      })
    );
    state = thunkAPI.getState() as RootState;
    const { data } = await GraphApi.createState(userAccountId || accountId, projectShortName, {
      stateToCreate: revertScreen,
      parentThemePath: stateToDelete.theme,
      parentStatePath,
      file: {
        filename: revertScreen.filename,
        lastModification: state.JGraphReducer.graph.files[revertScreen.filename],
      },
    });

    await thunkAPI.dispatch(batchUpdateStickers(revertEvent.prevState.JGraphReducer.stickers));
    await thunkAPI.dispatch(getGraph({}));
    await thunkAPI.dispatch(getJGraphVisuals());
    await thunkAPI.dispatch(getGraph({}));

    thunkAPI.dispatch(reverting(false));
    mainSave$.next({
      type: 'delete',
      path: revertScreen.path,
      action: () => Promise.resolve(),
    });
    return data;
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertMoveStateInGroup, moveStateInGroup);
