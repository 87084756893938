import { Ace } from 'ace-builds';

export type CustomCompleterType = {
  insertMatch?: (
    insertEditor: Ace.Editor,
    data: { value: string; name: string; score: number; meta: string }
  ) => unknown;
};

export type CompletionType = {
  value: string;
  name: string;
  score: number;
  meta: string;
  completer?: CustomCompleterType;
}[];

export class BaseCompleter {
  completionStrings: string[];
  KEYWORDS: string[];
  NAME: string;
  priority: number;

  constructor(completionStrings: string[], KEYWORDS: string[], NAME: string, priority = 10_000) {
    this.completionStrings = completionStrings;
    this.KEYWORDS = KEYWORDS;
    this.NAME = NAME;
    this.priority = priority;
  }

  getCompletions = (value: string): CompletionType =>
    this.completionStrings
      .filter(completionString => completionString.startsWith(value) && completionString !== value)
      .map((completionString, index) => {
        return {
          value: completionString,
          name: completionString,
          score: this.priority + index,
          meta: this.NAME,
          completer: {
            insertMatch: function (insertEditor, data) {
              let insertValue = data.value.replace(value, '');
              const cursorPosition = insertEditor.getCursorPosition();
              insertEditor.session.insert(cursorPosition, insertValue);
            },
          },
        };
      });

  hasCompletions = (tokens: string[]) => {
    return this.completionStrings.some(
      completionString =>
        completionString.startsWith(tokens[tokens.length - 1]) && completionString !== tokens[tokens.length - 1]
    );
  };

  isApplicable = (tokens: string[]) => {
    return this.KEYWORDS.length > 0
      ? this.KEYWORDS.includes(tokens[tokens.length - 1]) ||
          (tokens[tokens.length - 1].trim().length > 0 && this.hasCompletions(tokens))
      : this.hasCompletions(tokens);
  };
}
