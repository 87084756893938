import { isObject, mapKeys, mergeWith } from 'lodash';
import { localization as analyticLocalization } from '@just-ai/analytic-front';
import { commonLocalization } from './decomposed/common.loc';
import { errorsLocalization } from './decomposed/errors.loc';
import { defaultHelpUrlsPathLocalization } from './decomposed/defaultHelpUrlsPath.loc';
import { channeltypeLocalization } from 'views/Channels/localization/channeltype.loc';

import { chatwidgeteditorLocalization } from '../views/ChatWidgetEditPage/localization/chatwidgeteditor.loc.js';
import { JGLocalization } from '../modules/JGraph/localization/JG.loc.js';
import { howToCreateCustomTagLocalization } from '../modules/JGraph/view/RightSideMenu/RSAddingMenu/HowToCreateCustomTag/howToCreateCustomTag.loc.js';
import { analyticstagsLocalization } from '../views/AnalyticsTags/localization/analyticstags.loc';
import { tokensLocalization } from '../views/TokensSettings/tokens.loc';

const Localize = require('localize');
require('decliner/dist/decliner.min');

const radix = 10;

const localize = new Localize({
  ...channeltypeLocalization,
  ...analyticLocalization,
  ...commonLocalization,
  ...errorsLocalization,
  ...chatwidgeteditorLocalization,
  ...JGLocalization,
  ...howToCreateCustomTagLocalization,
  ...analyticstagsLocalization,
  ...tokensLocalization,
});

localize.addTranslations = translate => {
  mapKeys(translate, key => {
    if (!localize.getTranslations().hasOwnProperty(key)) {
      localize.loadTranslations(translate);
    }
  });
};

function hasAllTranslates(obj) {
  return obj.hasOwnProperty('ru') && Boolean(obj.ru) && obj.hasOwnProperty('eng') && Boolean(obj.eng);
}

const getUrlPath = key => {
  const { helpUrl, helpUrlsPath } = localize?.appConfigVariables || {};
  if (!helpUrl || !helpUrlsPath) {
    return {
      eng: '',
      ru: '',
      cn: '',
      pt: '',
    };
  }

  const cnHelpUrl = helpUrl.cn ? {} : { cn: helpUrl.eng };
  const ptHelpUrl = helpUrl.pt ? {} : { pt: helpUrl.eng };

  let result = helpUrl;

  if (helpUrlsPath.hasOwnProperty(key)) {
    result = mergeWith({ ...helpUrl, ...cnHelpUrl, ...ptHelpUrl }, helpUrlsPath[key], (url, path) => url + path);
  }

  return result;
};

localize.decliner = (arr, count) => {
  return arr.decline(parseInt(count, radix));
};
localize.checkExistKey = key => {
  return key !== localize.translate(key);
};

localize.setAppConfigVariables = botadminAppConfig => {
  localize.appConfigVariables = {
    helpUrl:
      Boolean(botadminAppConfig.helpUrl) &&
      isObject(botadminAppConfig.helpUrl) &&
      hasAllTranslates(botadminAppConfig.helpUrl)
        ? botadminAppConfig.helpUrl
        : {
            eng: 'http://help.cloud.just-ai.com/en/jaicp',
            ru: 'http://help.cloud.just-ai.com/jaicp',
            cn: 'http://help.cloud.just-ai.com/en/jaicp',
          },
    helpUrlsPath: Boolean(botadminAppConfig.helpUrlsPath)
      ? { ...defaultHelpUrlsPathLocalization, ...botadminAppConfig.helpUrlsPath }
      : defaultHelpUrlsPathLocalization,
    productName: botadminAppConfig.productName,
  };

  localize.addTranslations({
    'AppHelpNavListItems help link': getUrlPath('AppHelpNavListItems help link'),
    'Entities docs link': getUrlPath('Entities docs link'),
    'Spelling correction link': getUrlPath('Spelling correction link'),
    'NLUSettings help link': getUrlPath('NLUSettings help link'),
    'tooltip calls link': getUrlPath('tooltip calls link'),
    'tooltip callsApi link': getUrlPath('tooltip callsApi link'),
    'Header:GithubTokenBanner.linkHref': getUrlPath('Header:GithubTokenBanner.linkHref'),
    'ProjectOverview: JAICP link': getUrlPath('ProjectOverview: JAICP link'),
    'Widget:spellingPopoverLink': getUrlPath('Widget:spellingPopoverLink'),
    'ProjectOverview: noInfo client helpLink': getUrlPath('ProjectOverview: noInfo client helpLink'),
    'ProjectOverview: noInfo helpLink clients': getUrlPath('ProjectOverview: noInfo helpLink clients'),
    'ProjectOverview: noInfo helpLink results': getUrlPath('ProjectOverview: noInfo helpLink results'),
    'ProjectOverview: tooltip results button link': getUrlPath('ProjectOverview: tooltip results button link'),
    'Analytic:Experiments modal main label documentation link': getUrlPath(
      'Analytic:Experiments modal main label documentation link'
    ),
    'Logs:LogsMasking.EditRule.ruleHelpLinkHref': getUrlPath('Logs:LogsMasking.EditRule.ruleHelpLinkHref'),
    'ImportModal:helpLink-intents': getUrlPath('ImportModal:helpLink-intents'),
    'ImportModal:helpLink-entities': getUrlPath('ImportModal:helpLink-entities'),
    'ImportModal:helpLink-dictionary': getUrlPath('ImportModal:helpLink-dictionary'),
    'tasks modal help link editorbe.deploy.bot': getUrlPath('tasks modal help link editorbe.deploy.bot'),
    'tasks modal help link editorbe.deploy.bot.info': getUrlPath('tasks modal help link editorbe.deploy.bot.info'),
    'UserTariffLimits popover btn link uniques': getUrlPath('UserTariffLimits popover btn link uniques'),
    'UserTariffLimits popover btn link nlu': getUrlPath('UserTariffLimits popover btn link nlu'),
    'UserTariffLimits popover btn link asr': getUrlPath('UserTariffLimits popover btn link asr'),
    'UserTariffLimits popover btn link telephony': getUrlPath('UserTariffLimits popover btn link telephony'),
    'UserTariffLimits popover btn link cailarequests': getUrlPath('UserTariffLimits popover btn link cailarequests'),
    'UserTariffLimits popover btn link cailarequests_tovie': getUrlPath(
      'UserTariffLimits popover btn link cailarequests_tovie'
    ),
    'CustomAsrTtsProviderSettings:token.link': getUrlPath('CustomAsrTtsProviderSettings:token.link'),
    'CustomAsrTtsProviderSettings:tinkoff.token.link': getUrlPath('CustomAsrTtsProviderSettings:tinkoff.token.link'),
    'CreateProject:tooltip-button-link': getUrlPath('CreateProject:tooltip-button-link'),
    'ProjectsDashboard: help links tutorial link': getUrlPath('ProjectsDashboard: help links tutorial link'),
    'ProjectsDashboard: help links course link': getUrlPath('ProjectsDashboard: help links course link'),
    'caila project language tooltip link': getUrlPath('caila project language tooltip link'),
    'ProjectEditForm helpLink': getUrlPath('ProjectEditForm helpLink'),
    'ProjectEditForm helpLink JAICF': getUrlPath('ProjectEditForm helpLink JAICF'),
    'ProjectEditForm instruction link': getUrlPath('ProjectEditForm instruction link'),
    'ProjectEditForm:GithubTokenWarning.linkHref': getUrlPath('ProjectEditForm:GithubTokenWarning.linkHref'),
    'MailingList: help link': getUrlPath('MailingList: help link'),
    'FAQ:activationNotification:url': getUrlPath('FAQ:activationNotification:url'),
    'AnswerBlocks:question_tooltip_link': getUrlPath('AnswerBlocks:question_tooltip_link'),
    'AnswerBlocks:answer_tooltip_link': getUrlPath('AnswerBlocks:answer_tooltip_link'),
    'AnswerModal:info_link': getUrlPath('AnswerModal:info_link'),
    'AnswerModal:info_link_image': getUrlPath('AnswerModal:info_link'),
    'AnswerModal:info_link_audio': getUrlPath('AnswerModal:info_link'),
    'AnswerModal:info_link_file': getUrlPath('AnswerModal:info_link'),
    'FAQ:cdqaModal:linkUrl': getUrlPath('FAQ:cdqaModal:linkUrl'),
    'SummaryEdit:faq_link': getUrlPath('SummaryEdit:faq_link'),
    'SummaryEdit:answer_link': getUrlPath('SummaryEdit:answer_link'),
    'LLTopPanel:docsLink_url': getUrlPath('LLTopPanel:docsLink_url'),
    'LogLabelingPage:spellcheck_url': getUrlPath('LogLabelingPage:spellcheck_url'),
    'VscodeExtensionBanner:Link': getUrlPath('VscodeExtensionBanner:Link'),
    'RightSideMenu:Header:LinkToDock:a': getUrlPath('RightSideMenu:Header:LinkToDock:a'),
    'RightSideMenu:Header:LinkToDock:intent': getUrlPath('RightSideMenu:Header:LinkToDock:intent'),
    'AnswerSettings:LinkToFormattingDocs': getUrlPath('AnswerSettings:LinkToFormattingDocs'),
    'HowToCreateCustomTag:linkToDock': getUrlPath('HowToCreateCustomTag:linkToDock'),
    'ProjectEditConfig:NLU:ClassificationAlgorithm:dockLink:Link': getUrlPath(
      'ProjectEditConfig:NLU:ClassificationAlgorithm:dockLink:Link'
    ),
    'AnalyticsTags:Subtitle:Doc:Link': getUrlPath('AnalyticsTags:Subtitle:Doc:Link'),
    'BotScorer:Link': getUrlPath('BotScorer:Link'),
  });
};

localize.throwOnMissingTranslation(false);

export default localize;

export const { translate: t } = localize;

export const tWithCheck = (...args) => {
  const translated = t(...args);
  if (translated === args[0]) {
    return '';
  }
  return translated;
};

/**
 * Возвращает значение конкотенированный строки состоящей из baseKey + значение из вызова функции Intl.PluralRules.select -> одно из [ zero, one, two, few, many, other ]
 *
 * @param {string} baseKey - localization string without [ zero, one, two, few, many, other ]
 * @param {number} numberValue
 * @returns {string} - `${baseKey}_${pluralRight}`
 */
export const tWithPlural = (baseKey, numberValue) => {
  const pluralValue = new Intl.PluralRules(
    Intl.PluralRules.supportedLocalesOf([localize.getLocale()], { localeMatcher: 'lookup' })?.[0] || 'en-US'
  );
  const pluralRight = pluralValue.select(numberValue);
  return t(`${baseKey}_${pluralRight}`, numberValue);
};
