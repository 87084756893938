import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { GraphApi } from '../utils';
import { JGraphTheme } from '../types';
import { renameThemeInState } from '..';
import getGraph from './getGraph';

type RenameThemePayload = {
  theme: JGraphTheme;
  newName: string;
};
const renameTheme = createAsyncThunk('JGraph/renameTheme', async (renameThemePayload: RenameThemePayload, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  const theme = renameThemePayload.theme;

  thunkAPI.dispatch(renameThemeInState({ oldValue: theme.value, newValue: renameThemePayload.newName }));

  const action = async () => {
    await GraphApi.renameTheme(userAccountId || accountId, projectShortName, {
      targetTheme: theme.value,
      newTheme: renameThemePayload.newName,
      files: theme.filenames.map(filename => ({
        filename,
        lastModification: state.JGraphReducer.graph.files[filename],
      })),
    });
    await thunkAPI.dispatch(getGraph({}));
  };
  const actionPromise = action();
  mainSave$.next({ type: 'rename', path: theme.value, action: () => actionPromise });
  return actionPromise;
});

export const revertRenameTheme = createAsyncThunk(
  'JGraph/revertRenameTheme',
  async (revertEvent: RevertEvent<RenameThemePayload>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const theme = revertEvent.payload.theme;

    const currentTheme = state.JGraphReducer.graph.themes.find(theme => theme.value === revertEvent.payload.newName);
    if (!currentTheme) return;

    thunkAPI.dispatch(renameThemeInState({ oldValue: revertEvent.payload.newName, newValue: theme.value }));

    const action = async () => {
      await GraphApi.renameTheme(userAccountId || accountId, projectShortName, {
        targetTheme: currentTheme.value,
        newTheme: theme.value,
        files: currentTheme.filenames.map(filename => ({
          filename,
          lastModification: state.JGraphReducer.graph.files[filename],
        })),
      });
      await thunkAPI.dispatch(getGraph({}));
    };
    const actionPromise = action();
    mainSave$.next({ type: 'rename', path: theme.value, action: () => actionPromise });
    return actionPromise;
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertRenameTheme, renameTheme);
